import { useIndex } from '../Factories/useIndex';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  FaqItem,
  FaqItemFilters,
  FaqItemIncludes,
  FaqItemSort,
} from '../Types/Resources/Faq';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface Props
  extends HookParams<
    typeof FaqItemIncludes,
    typeof FaqItemFilters,
    typeof FaqItemSort
  > {
  portal?: UUID;
}

export function useFaqItems(
  { portal, ...queryParameters }: Props = {},
  settings?: IUseQueryOptions<DataDocument<FaqItem[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.faqItems',
      baseUri: `/v1/portals/${portal || defaultPortal}/faq_items`,
      invalidate: ['faq_items', 'faq_item'],
      queryParameters,
    },
    context
  );
  return useIndex<FaqItem>(request, settings);
}
