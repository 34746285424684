import React, { useCallback, useMemo } from 'react';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { EducationProfile } from '@brainstud/academy-api/Types/Resources/EducationProfile';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { Search } from '@brainstud/ui/Form Input/Search';
import { Chip } from '@brainstud/ui/Static/Chip';
import { Delete } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { GridListToggle } from 'Components/GridListToggle';
import { useTranslator } from 'Providers/Translator';
import { useCourseList } from '../../../../../Providers/CourseListProvider/useCourseList';
import FilterDrawer from './FilterDrawer/FilterDrawer';
import { sbuSorter } from './SbuSortFn';
import styles from './CatalogHeader.module.css';

const cx = classNames.bind(styles);

const CatalogHeader = () => {
  const [t] = useTranslator();
  const [, { data: document }] = usePortalCoursesData();
  const educationProfiles = useMemo(
    () => document?.findByType<EducationProfile>('education_profiles'),
    [document]
  );

  const {
    categories,
    subcategories,
    courseCount,
    totalCourses,
    searchQuery,
    toggleProfile,
    filters,
    viewType,
    dispatch,
  } = useCourseList();

  const resetFilters = useCallback(() => {
    dispatch({
      type: 'FILTER_SBU',
      payload: undefined,
    });
    dispatch({
      type: 'FILTER_PROFILES',
      payload: undefined,
    });
  }, [dispatch]);

  return (
    <Container className={cx(styles.container)}>
      <div className={cx(styles.base)}>
        <div className={cx(styles.row)}>
          <div className={cx(styles.utilities)}>
            <FilterDrawer />
            {categories.length > 1 && (
              <Dropdown
                onChange={(value) =>
                  dispatch({
                    type: 'FILTER_CATEGORY',
                    payload: value || undefined,
                  })
                }
                placeholder={`${t('components.catalog.categoryPlaceholder')} (${totalCourses})`}
              >
                {categories.map((category) =>
                  category.productSubCategories?.() ? (
                    <Dropdown.Group
                      key={`${category.id}-group`}
                      label={`${category.label} (${courseCount[category.id] || 0})`}
                    >
                      <>
                        <Dropdown.Option value={category.id}>
                          {`${t('components.catalog.allSubCategories', { subcategoryLabel: category.labelPlural })} (${courseCount[category.id] || 0})`}
                        </Dropdown.Option>
                        {category
                          .productSubCategories?.()
                          ?.filter((subcat1) =>
                            subcategories.some(
                              (subcat2) => subcat1.id === subcat2.id
                            )
                          )
                          .map((subcategory) => (
                            <Dropdown.Option
                              key={subcategory.id}
                              value={subcategory.id}
                            >
                              {subcategory.labelPlural}
                              {courseCount[subcategory.id] !== undefined &&
                                ` (${courseCount[subcategory.id] || '0'})`}
                            </Dropdown.Option>
                          ))}
                      </>
                    </Dropdown.Group>
                  ) : (
                    <Dropdown.Option key={category.id} value={category.id}>
                      {`${category.labelPlural} (${courseCount[category.id]})`}
                    </Dropdown.Option>
                  )
                )}
              </Dropdown>
            )}
          </div>
          <div className={cx(styles.utilities, styles.right)}>
            <Search
              onChange={(e) =>
                dispatch({ type: 'SEARCH', payload: e.target.value })
              }
              onClose={() => dispatch({ type: 'SEARCH' })}
              placeholder={t('components.catalog.searchPlaceholder')}
              defaultValue={searchQuery || ''}
              block
            />
            <div>
              <GridListToggle
                value={viewType === 'grid'}
                onChange={() => dispatch({ type: 'TOGGLE_VIEWTYPE' })}
              />
            </div>
          </div>
        </div>
        {(!!filters?.sbu?.length || !!filters?.profiles?.length) && (
          <div className={cx('enabled-filters')}>
            <span>{`${t('components.catalog.filters')}: `}</span>
            <div className={cx('chip-list')}>
              {filters?.sbu?.sort(sbuSorter).map((sbu) => (
                <Chip
                  key={sbu}
                  label={`${t('components.catalog.sbu')}: ${sbu}`}
                  className={cx('chip')}
                  onClose={() =>
                    dispatch({
                      type: 'FILTER_SBU',
                      payload: filters?.sbu?.filter(
                        (orginalSbu) => orginalSbu !== sbu
                      ),
                    })
                  }
                />
              ))}
              {filters?.profiles?.map((profileId) => {
                const profile = educationProfiles?.find(
                  (eduProfile) => eduProfile.id === profileId
                );
                return (
                  <Chip
                    key={profileId}
                    label={profile?.title || ''}
                    className={cx('chip')}
                    onClose={() => toggleProfile(profileId)}
                  />
                );
              })}
              <Button link onClick={resetFilters}>
                <Delete />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default CatalogHeader;
