import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  ProductCategory,
  ProductCategoryCreateInput,
  ProductCategoryFilters,
  ProductCategoryIncludes,
  ProductCategorySorts,
  ProductCategoryUpdateInput,
} from '../Types/Resources/ProductCategory';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IProductCategoryProps
  extends HookParams<
    typeof ProductCategoryIncludes,
    typeof ProductCategoryFilters,
    typeof ProductCategorySorts
  > {
  productCategory?: UUID;
  portal?: UUID;
}

export function useProductCategory(
  { portal, productCategory, ...queryParameters }: IProductCategoryProps = {},
  settings?: IUseQueryOptions<DataDocument<ProductCategory>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      productCategory: defaultProductCategory,
      portal: defaultPortal,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(productCategory || defaultProductCategory) &&
        !!(portal || defaultPortal),
      baseName: 'v1.portals.productCategories',
      baseUri: `/v1/portals/${portal || defaultPortal}/product_categories`,
      uri: `/${productCategory || defaultProductCategory}`,
      invalidate: [
        'product_categories',
        'product_category',
        'category',
        'categories',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ProductCategory,
    ProductCategoryCreateInput,
    ProductCategoryUpdateInput
  >(request, settings);
}

export function useProductCategories(
  {
    portal,
    ...queryParameters
  }: Omit<IProductCategoryProps, 'productCategory'> = {},
  settings?: IUseQueryOptions<DataDocument<ProductCategory[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.productCategories',
      baseUri: `/v1/portals/${portal || defaultPortal}/product_categories`,
      invalidate: [
        'product_categories',
        'product_category',
        'category',
        'categories',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<ProductCategory, ProductCategoryCreateInput>(
    request,
    settings
  );
}
