import React, { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Badge } from '@brainstud/ui/Static/Badge';
import {
  Close,
  CloudDownload,
  PlayCircleFilled,
  Settings,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { EnrollmentSettingsModal } from 'Modals';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { useCourseStartup } from '../../Hooks/useCourseStartup';
import { TeaserModal } from '../../Modals';
import styles from './CourseInfoPanel.module.css';

export interface CourseInfoPanelProps {
  className?: string;
  course: number | string;
  onClose: () => void;
}

const cx = classNames.bind(styles);

const CourseInfoPanel = ({
  course: courseId,
  className,
  onClose,
}: CourseInfoPanelProps) => {
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();

  const [{ data: courses }, { isLoading }] = usePortalCoursesData();
  const [me] = useMe();
  const course = useMemo(
    () => courses.find((item) => item.id === courseId),
    [courses, courseId]
  );

  // Added to animate height at render
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  const isComingSoon = course?.status === 'COMING_SOON';
  const isLoggedIn = !!me?.account;

  // Sets default thumbnail and poster if it does not exist
  const hasThumbnail =
    course?.metadata?.media?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !==
      -1 || course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1;
  const defaultThumbnail = '/media/abstract-thumbnail.jpg';
  const defaultPoster = '/media/abstract-banner.jpg';
  const thumbnail = hasThumbnail
    ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
    : defaultThumbnail;
  const hasPoster =
    course?.metadata?.media?.posterUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !==
      -1 || course?.posterUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1;
  const poster = hasPoster
    ? course?.metadata?.media?.posterUrl || course?.posterUrl
    : defaultPoster;

  // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
  const labels = course?.metadata?.informationLines || course?.metadata?.labels;
  const properties =
    // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
    course?.metadata?.informationLines || course?.metadata?.properties;

  const { handleOpenCourse, requires, label, pricing, hasAccess } =
    useCourseStartup({ course });

  const externalPaymentUrl = pricing.external
    ? course
        ?.catalogProducts?.()
        .find((product) => product.priceModel === 'external')
        ?.externalPaymentUrl
    : undefined;

  return isLoading || !course ? (
    <Loading />
  ) : (
    <AnimateHeight
      className={cx(styles.base, className)}
      height={open ? 'auto' : 0}
      duration={500}
      style={{ margin: open ? '10px 0' : 0 }}
    >
      <img className={styles.background} src={poster} alt="" />
      <Container className={styles.container}>
        <figure className={styles.thumbnail}>
          <img src={thumbnail} alt="" />
        </figure>
        {course?.metadata?.media?.teaserUrl && (
          <Button
            className={styles.teaserButton}
            link
            onClick={() =>
              openModal(TeaserModal, {
                teaserUrl: course?.metadata?.media?.teaserUrl,
              })
            }
          >
            <PlayCircleFilled
              style={{ fontSize: 32, color: 'rgb(var(--primary-color))' }}
            />
            {t('components.catalog.view_trailer')}
          </Button>
        )}
        <div className={styles.content}>
          <div className={styles.badges}>
            {labels?.sbu && <Badge color="black">{`${labels.sbu} SBU`}</Badge>}

            {labels?.learningObjectsCount && (
              <Badge color="green">{`${labels.learningObjectsCount} ${t('components.catalog.assignments')}`}</Badge>
            )}

            {labels?.validatedCertifications && (
              <Badge color="aqua">
                {t('components.catalog.validatedExam')}
              </Badge>
            )}

            {course?.opener === 'scorm' && (
              <Badge color="white">
                {t('components.catalog.external_content')}
              </Badge>
            )}
          </div>
          {isLoggedIn && !requires.enrollment && (
            <Button
              link
              className={styles.settings}
              onClick={() =>
                openModal(EnrollmentSettingsModal, {
                  onClose: () => closeModal(),
                  course,
                })
              }
            >
              <Settings />
            </Button>
          )}
          <Button link className={styles.close} onClick={onClose}>
            <Close style={{ fontSize: '20px' }} />
          </Button>
          {labels?.original && (
            <div className={cx(styles.original)}>
              <strong>{labels.original}</strong> ORIGINAL
            </div>
          )}
          <h1 className={cx(styles.title)}>{course.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: sanitizer(course.description) }}
          />
          {properties?.supplies && (
            <section className={styles.supplies}>
              <strong>{t('components.catalog.supplies')}</strong>
              <p>{properties.supplies}</p>
            </section>
          )}

          <div>
            {(course.educationProfiles?.() || []).map((profile) => (
              <Badge key={profile.id} color="white">
                {profile.title}
              </Badge>
            ))}
          </div>

          <div className={styles.actions}>
            {isLoggedIn && !isComingSoon && (
              <>
                {hasAccess && (
                  <Button
                    className={styles.enrollButton}
                    onClick={() => handleOpenCourse()}
                  >
                    <span>{label}</span>
                  </Button>
                )}
              </>
            )}

            {course.factsheetUrl && (
              <Button
                className={styles.factsheet}
                outline
                target="_blank"
                white
                href={course.factsheetUrl}
              >
                <CloudDownload />
                <span>{t('components.catalog.factsheet')}</span>
              </Button>
            )}
          </div>

          <aside>
            {properties?.dossiers && (
              <p>{`${t('components.catalog.files')}: ${properties.dossiers}`}</p>
            )}
            {properties?.developers && (
              <p>{`${t('components.catalog.developers')}: ${properties.developers}`}</p>
            )}
            {properties?.genre && (
              <p>{`${t('components.catalog.genre')}: ${properties.genre}`}</p>
            )}
          </aside>
        </div>
      </Container>
      {!isComingSoon && (!hasAccess || !isLoggedIn) && (
        <div className={cx(styles.bottom)}>
          <Container className={cx(styles.callToActions)}>
            <div className={cx(styles.login)}>
              <p>
                <strong>
                  {t('components.catalog.actions.want_to_purchase')}
                </strong>
              </p>
              <p>
                {!isLoggedIn &&
                  t('components.catalog.actions.descriptions.login')}
                {requires.payment &&
                  t('components.catalog.actions.descriptions.flat_fee')}
                {requires.subscription &&
                  t('components.catalog.actions.descriptions.subscription')}
              </p>
            </div>

            {(!pricing.free || !isLoggedIn) && (
              <div className={cx(styles.purchases)}>
                <p>
                  {/* <strong>{t('components.catalog.actions.make_your_choice')}</strong> */}
                </p>
                {!isLoggedIn ? (
                  <Button outline white href="/auth">
                    {t('components.catalog.actions.login')}
                  </Button>
                ) : (
                  <>
                    {!course.locked && isLoggedIn && (
                      <>
                        <div>
                          {requires.subscription && (
                            <Button
                              className={styles.ctaButton}
                              href="/account/subscriptions/options"
                            >
                              <span>
                                {typeof label === 'object'
                                  ? label.subscription
                                  : label}
                              </span>
                            </Button>
                          )}
                        </div>
                        <div>
                          {requires.payment && (
                            <Button
                              white={requires.subscription}
                              outline={requires.subscription}
                              className={styles.ctaButton}
                              target={pricing.external ? '_BLANK' : undefined}
                              href={
                                pricing.external
                                  ? externalPaymentUrl
                                  : undefined
                              }
                              onClick={
                                pricing.flatFee
                                  ? () => handleOpenCourse('flat_fee')
                                  : undefined
                              }
                            >
                              <span>
                                {typeof label === 'object'
                                  ? label.flatFee
                                  : label}
                              </span>
                            </Button>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </Container>
        </div>
      )}
    </AnimateHeight>
  );
};

export default CourseInfoPanel;
