/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useFaqItems } from '@brainstud/academy-api/Hooks/useFaqItems';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import {
  FaqAudience,
  FaqCategory,
} from '@brainstud/academy-api/Types/Resources/Faq';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Tabs, TabsItem } from 'Components/Tabs';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './Faq.module.css';

const cx = classNames.bind(styles);

const Faq = ({ onClose }: any) => {
  const [t] = useTranslator();
  const [data, { data: document }] = useFaqItems();
  const audiences = useMemo(
    () => document?.findByType<FaqAudience>('faq_audiences'),
    [document]
  );
  const categories = useMemo(
    () => document?.findByType<FaqCategory>('faq_categories'),
    [document]
  );

  const [activeAudience, setActiveAudience] = useState<
    string | number | null
  >();
  const [activeCategory, setActiveCategory] = useState<
    string | number | null
  >();
  const [me] = useMe();
  const isLoggedIn = useMemo(() => !!me?.account, [me]);

  const items = data.filter(
    (item) =>
      item?.faqCategory?.().id === activeCategory &&
      item?.faqAudience?.().id === activeAudience
  );
  const itemsActiveAudience = useMemo(
    () =>
      categories?.map(
        (category) =>
          data.filter(
            (item) =>
              item?.faqCategory?.().id === category.id &&
              item?.faqAudience?.().id === activeAudience
          ).length
      ) || [],
    [categories, data, activeAudience]
  );
  const categoriesWithQuestions = useMemo(
    () =>
      categories?.filter(
        (category, categoryIdx) => itemsActiveAudience[categoryIdx] > 0
      ),
    [categories, itemsActiveAudience]
  );

  useEffect(() => {
    if (!activeAudience && audiences?.[0]) setActiveAudience(audiences[0].id);
    if (!activeCategory && categories?.[0]) setActiveCategory(categories[0].id);
  }, [data, activeAudience, activeCategory, audiences, categories]);

  return (
    <section className={cx(styles.faq)}>
      <header>
        {isLoggedIn && (
          <Button quiet small onClick={onClose} className={cx(styles.close)}>
            <Close fontSize="large" />
          </Button>
        )}
        <h1>{t('components.faq.title')}</h1>
      </header>

      {audiences && audiences.length > 1 && (
        <Tabs className={cx(styles.audiences)}>
          {audiences.map((audience) => (
            <TabsItem
              key={audience.id}
              onClick={() => setActiveAudience(audience.id)}
              active={activeAudience === audience.id}
            >
              {audience.title}
            </TabsItem>
          ))}
        </Tabs>
      )}

      {categoriesWithQuestions && categoriesWithQuestions.length > 1 && (
        <Tabs className={cx(styles.categories)}>
          {categoriesWithQuestions.map((category) => (
            <TabsItem
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              active={activeCategory === category.id}
            >
              {category.title}
            </TabsItem>
          ))}
        </Tabs>
      )}

      <div className={cx(styles.items)}>
        {items.map((item, index, array) => (
          <Accordion
            key={item.id}
            title={item.question}
            quiet
            open={array.length < 2}
          >
            <div
              className={cx(styles.answer)}
              dangerouslySetInnerHTML={{ __html: sanitizer(item.answer) }}
            />
          </Accordion>
        ))}
      </div>
    </section>
  );
};

export default Faq;
