import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { useProductCategories } from '@brainstud/academy-api/Hooks/useProductCategories';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import { ProductCategory } from '@brainstud/academy-api/Types/Resources/ProductCategory';
import { useLocalStorage } from 'Hooks/BrowserStorage/useLocalStorage';
import queryString from 'query-string';
import { isPresent } from 'ts-is-present';
import CourseListContext from './CourseListContext';
import {
  CourseListInitialState,
  CourseListReducer,
  ICourseListState,
} from './CourseListReducer';

type CourseListProviderProps = {
  /** The configurations are stored between reloads in the localStorage based on this identifier */
  uniqueStorageId: string;
  /** Event handler that fires when a different category is selected */
  onCategoryChange?: (category: undefined | string) => void;
  /** List of courses to filter and search through */
  courses: Course[];
  /** The category that is selected by default */
  category?: string;
  /** When quickStart is enabled, then you will open the course link instead of the CourseInfoDrawer. */
  quickStart?: boolean;
  children: React.ReactNode;
};

/**
 * CourseListProvider.
 *
 * Keeps track of the requested filtering, sorting, category and viewType and filters the total list of courses
 * provided based on those configurations.
 */
export const CourseListProvider = ({
  uniqueStorageId,
  courses: courseList,
  quickStart = true,
  category: defaultCategory,
  children,
  onCategoryChange,
}: CourseListProviderProps) => {
  const [localStorage, setLocalStorage] = useLocalStorage<
    Partial<ICourseListState>
  >(uniqueStorageId, {});
  const [
    {
      searchQuery,
      viewType,
      sort,
      filters: { sbu, profiles, category },
    },
    dispatch,
  ] = useReducer(CourseListReducer, {
    ...CourseListInitialState,
    ...localStorage,
    filters: {
      category: defaultCategory,
      ...(localStorage?.filters || {}),
    },
  });

  // Filters
  const toggleProfile = useCallback((profile: string) => {
    dispatch({
      type: 'TOGGLE_PROFILES',
      payload: [profile],
    });
  }, []);

  useEffect(() => {
    if (onCategoryChange) {
      onCategoryChange(category);
    }
  }, [category, onCategoryChange]);

  const [{ data: initialCategories }] = useProductCategories({
    include: ['product_sub_categories'],
  });
  const [, { data: document }] = usePortalCoursesData();

  const courseProductCategories = useMemo(
    () =>
      document
        ?.findByType<ProductCategory>('product_categories')
        // eslint-disable-next-line max-len
        .filter((productCategory) =>
          courseList.some(
            (course) => course.productCategory?.().id === productCategory.id
          )
        ),
    [document, courseList]
  );
  const categories = useMemo(
    () =>
      // eslint-disable-next-line max-len
      initialCategories.filter((initialCategory) =>
        courseProductCategories?.some((item) => item.id === initialCategory.id)
      ),
    [initialCategories, courseProductCategories]
  );

  const subcategories = useMemo(
    () =>
      courseList
        .map((item) => item.productSubCategory?.())
        .filter(isPresent)
        .filter(
          (one, index, array) =>
            array.findIndex((two) => two.id === one.id) === index
        ),
    [courseList]
  );

  const courseCount = useMemo(
    () =>
      courseList?.reduce<{ [key: string]: number }>((categoryList, course) => {
        const categoryId = course.productCategory?.()?.id;
        const subCategoryId = course.productSubCategory?.()?.id;
        const totalCategories = categoryId
          ? {
              ...categoryList,
              [categoryId]: (categoryList[categoryId] || 0) + 1,
            }
          : categoryList;
        return subCategoryId
          ? {
              ...totalCategories,
              [subCategoryId]: (totalCategories[subCategoryId] || 0) + 1,
            }
          : totalCategories;
      }, {}) || {},
    [courseList]
  );

  const totalCourses = courseList.length;

  const { category: categoryLabel } = queryString.parse(
    window.location.search
  ) as { category?: string };
  const [queryStringConfigLoaded, setQueryStringConfigLoaded] = useState(false);
  useEffect(() => {
    if (!queryStringConfigLoaded && categories.length > 0) {
      setQueryStringConfigLoaded(true);
      const lowerCaseCategoryLabel = categoryLabel?.toLowerCase();
      if (lowerCaseCategoryLabel) {
        const existingCategory = categories.filter(
          (item) => item.label.toLowerCase() === lowerCaseCategoryLabel
        );
        dispatch({
          type: 'FILTER_CATEGORY',
          payload: existingCategory[0]?.id,
        });
      }
    }
  }, [categories, categoryLabel, queryStringConfigLoaded]);

  useEffect(() => {
    setLocalStorage({
      viewType,
      filters: {
        sbu,
        profiles,
        category,
      },
      sort,
    });
  }, [viewType, sbu, profiles, sort, setLocalStorage, category]);

  // Resetting all filters
  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
    });
  }, []);

  // Creating the course list
  const courses = useMemo(
    () =>
      courseList
        .filter((course) => {
          if (searchQuery) {
            return course.title
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }

          const categoryMatch =
            !categories ||
            category === course.productCategory?.()?.id ||
            category === course.productSubCategory?.()?.id ||
            category === undefined;

          const profileMatch =
            !profiles?.length ||
            profiles.some((profile) =>
              course
                ?.educationProfiles?.()
                .some((courseProfile) => courseProfile.id === profile)
            );

          const sbuMatch =
            !sbu?.length ||
            sbu.some(
              (sbuFilterValue) =>
                sbuFilterValue ===
                (course?.metadata?.informationLines?.sbu ||
                  // @ts-ignore FIXME: Remove when all courses have adopted the informationLines approach.
                  course?.metadata?.labels?.sbu ||
                  'none')
            );

          return categoryMatch && profileMatch && sbuMatch;
        })
        .sort((a, b) => {
          const courseAttributeA =
            sort.attribute === 'sbu'
              ? // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
                a?.metadata?.informationLines?.sbu || a?.metadata?.labels?.sbu
              : a[sort.attribute];
          const courseAttributeB =
            sort.attribute === 'sbu'
              ? // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
                b?.metadata?.informationLines?.sbu || b?.metadata?.labels?.sbu
              : b[sort.attribute];
          if (sort.direction === 'ascending') {
            return courseAttributeA < courseAttributeB ? -1 : 1;
          }
          return courseAttributeA > courseAttributeB ? -1 : 1;
        })
        .sort((a, b) => {
          // Sort on enrollment
          const hasEnrollmentA = !!a.enrollment;
          const hasEnrollmentB = !!b.enrollment;
          if (hasEnrollmentA && !hasEnrollmentB) return -1;
          if (!hasEnrollmentA && hasEnrollmentB) return 1;
          return 0;
        }),
    [courseList, categories, profiles, category, sbu, sort, searchQuery]
  );

  const state = useMemo(
    () => ({
      catalog: courseList,
      categories,
      subcategories,
      courses,
      courseCount,
      totalCourses,
      quickStart,
      toggleProfile,
      searchQuery,
      sort,
      viewType,
      filters: {
        category,
        profiles,
        sbu,
      },
      reset,
      dispatch,
    }),
    [
      courseList,
      courses,
      courseCount,
      totalCourses,
      quickStart,
      toggleProfile,
      viewType,
      reset,
      searchQuery,
      categories,
      subcategories,
    ]
  );

  return (
    <CourseListContext.Provider value={state}>
      {children}
    </CourseListContext.Provider>
  );
};
