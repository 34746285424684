import { createContext, Dispatch } from 'react';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import { ProductCategory } from '@brainstud/academy-api/Types/Resources/ProductCategory';
import { ProductSubCategory } from '@brainstud/academy-api/Types/Resources/ProductSubCategory';
import { CourseListActions, ICourseListState } from './CourseListReducer';

export interface ICourseListContext extends ICourseListState {
  quickStart: boolean;
  catalog: Course[];
  categories: ProductCategory[];
  subcategories: ProductSubCategory[];
  courses: Course[];
  courseCount: { [p: string]: number } | number;
  totalCourses: number;
  toggleProfile: (val: string) => void;
  reset: () => void;
  dispatch: Dispatch<CourseListActions>;
}

export default createContext<null | ICourseListContext>(null);
