import React, { useMemo } from 'react';
import { usePortalCoursesData } from '@brainstud/academy-api/Hooks/usePortalCourses';
import { Badge } from '@brainstud/ui/Static/Badge';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import {
  isCompleted,
  isNew,
} from 'Modules/blended-learning-catalog-panel/Utils/courseHelpers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as PartyPopper } from 'public/media/icons/party_popper.svg';
import { usePortalParameter } from '../../Hooks/usePortalParameter';
import styles from './CourseListItem.module.css';

const cx = classNames.bind(styles);

type CourseListItemProps = {
  course: string | number;
  active: boolean;
  onClick?: () => void;
  className?: string;
};

const CourseListItem = ({
  course: courseId,
  active = false,
  onClick,
  className,
}: CourseListItemProps) => {
  const [{ data: courses }, { isLoading }] = usePortalCoursesData();
  const course = courses.find((item) => item.id === courseId);
  const product = useMemo(
    () =>
      course
        ?.catalogProducts?.()
        .find((item) => item.priceModel === 'flat_fee'),
    [course]
  );
  const [t] = useTranslator();
  const educationProfiles = useMemo(
    () => course?.educationProfiles?.() || [],
    [course]
  );
  const beforeVatPrice = !!usePortalParameter('show_prices_excluding_vat');
  const priceTag =
    product?.[beforeVatPrice ? 'priceBeforeVat' : 'priceAfterVat'];

  const hasThumbnail =
    (course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl) &&
    (course?.metadata?.media?.thumbnailUrl?.search(
      /(.jpg|.png|.jpeg|.webp)/
    ) !== -1 ||
      course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1);

  const defaultThumbnail = '/media/abstract-thumbnail.jpg';
  const thumbnail = hasThumbnail
    ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
    : defaultThumbnail;

  // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
  const labels = course?.metadata?.informationLines || course?.metadata?.labels;

  return isLoading || !course ? (
    <Loading />
  ) : (
    <Container>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={cx('item', { open: active }, className)}
        onClick={onClick}
      >
        <img className={styles.thumbnail} src={thumbnail} alt="" />
        <div className={styles.info}>
          <p className={styles.title}>
            <strong>{course.title}</strong>
          </p>
          <div className={styles.badges}>
            <div className={styles.between}>
              {isNew(course) && (
                <Badge color="yellow">{t('components.course_card.new')}</Badge>
              )}
              {course.status === 'COMING_SOON' && (
                <Badge color="yellow">
                  {t('components.course_card.coming_soon')}
                </Badge>
              )}
              {educationProfiles.map((profile) => (
                <Badge
                  key={profile.id}
                  className={styles.eduBadge}
                  color="white"
                >
                  {profile.title}
                </Badge>
              ))}

              {labels?.sbu && (
                <Badge color="black" className={styles.sbuBadge}>
                  {`${labels.sbu} SBU`}
                </Badge>
              )}
            </div>
          </div>
        </div>
        {!!isCompleted(course) && (
          <div className={styles.completed}>
            <Badge color="white-full">
              <div className={cx(styles.completedContent)}>
                <PartyPopper />
                <strong>{t('components.course_card.completed')}</strong>
              </div>
            </Badge>
          </div>
        )}

        {labels?.sbu && (
          <div className={styles.sbu}>
            <Badge color="black">{`${labels.sbu} SBU`}</Badge>
          </div>
        )}
        {labels?.learningObjectsCount && (
          <div className={styles.count}>
            {!!product && (
              <Badge color="yellow" className={styles.badgeTopLeft}>
                {priceTag?.toLocaleString('nl-nl', {
                  style: 'currency',
                  currency: product.currency,
                })}
              </Badge>
            )}
            <Badge color="green">{`${labels.learningObjectsCount} ${t('components.catalog.assignments')}`}</Badge>
          </div>
        )}
      </div>
    </Container>
  );
};

export default CourseListItem;
