import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Course } from '@brainstud/academy-api/Types/Resources/Course';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { CourseInfoPanel } from 'Modules/blended-learning-catalog-panel/Components/CourseInfoPanel';
import { CourseListItem } from 'Modules/blended-learning-catalog-panel/Components/CourseListItem';
import { useCourseList } from 'Modules/blended-learning-catalog-panel/Providers/CourseListProvider/useCourseList';
import { useTranslator } from 'Providers/Translator';
import queryString from 'query-string';
import styles from './CatalogList.module.css';

const cx = classNames.bind(styles);

type Props = {
  passedCourses?: Course[];
};

const CatalogList = ({ passedCourses }: Props) => {
  const { courses: providedCourses, quickStart } = useCourseList();
  const { course: preOpenedCourseId } = queryString.parse(
    window.location.search
  ) as { course?: string };
  const [openListItem, setOpenListItem] = useState<null | string | number>(
    (!quickStart && preOpenedCourseId) || null
  );
  const [t] = useTranslator();
  const scrollRef = useRef<HTMLDivElement>(null);
  const courses = passedCourses || providedCourses;

  const handleListItemClick = useCallback(
    (courseId: null | string | number) => {
      if (openListItem === courseId) {
        setOpenListItem(null);
      } else {
        setOpenListItem(courseId);
      }
    },
    [openListItem]
  );

  useEffect(() => {
    if (openListItem === null) return;

    scrollRef.current
      ?.getElementsByClassName(`row-${openListItem}`)[0]
      ?.scrollIntoView(true);
  }, [openListItem]);

  return (
    <div ref={scrollRef} className={cx('list')}>
      <Container>
        <div className={cx('item', 'header')}>
          <div className={styles.info}>{t('components.catalog.course')}</div>
        </div>
      </Container>
      {courses.map((course) => (
        <React.Fragment key={course.id}>
          <CourseListItem
            className={`row-${course.id}`}
            course={course.id}
            active={course.id === openListItem}
            onClick={() => handleListItemClick(course.id)}
          />
          {openListItem === course.id && (
            <CourseInfoPanel
              className={styles.infoPanel}
              course={course.id}
              onClose={() => handleListItemClick(null)}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CatalogList;
